<template>
  <!-- 缺省页 -->
  <div class="default-page">
    <div class="default-item" v-if="status == 'nopic'">
      <img src="@/assets/image/default/nopic.png" alt="" />
      <p>抱歉，没有找到您搜索的素材，建议您：</p>
      <ul>
        <li>请检查输入的关键词是否有误；</li>
        <li>更换为相似词或常见词</li>
        <li>缩短搜索内容长度，或使用少量关键词，并使用空格分隔多个关键词</li>
      </ul>
    </div>
    <div class="default-item neterror" v-if="status == 'neterror'">
      <img src="@/assets/image/default/neterror.png" alt="" />
      <p>系统繁忙，请稍后再试</p>
      <ul>
        <li>请试试以下办法；</li>
        <li>检查网线、调制解调器和路由器</li>
        <li>重新连接到 Wi-Fi 网络</li>
      </ul>
    </div>
    <div class="default-item nonet" v-if="status == 'nonet'">
      <img src="@/assets/image/default/nonet.png" alt="" />
      <p>未连接到互联网</p>
      <ul>
        <li>请试试以下办法；</li>
        <li>检查网线、调制解调器和路由器</li>
        <li>重新连接到 Wi-Fi 网络</li>
      </ul>
    </div>
    <div class="default-item nocollect" v-if="status == 'nocollect'">
      <img src="@/assets/image/default/nocollect.png" alt="" />
      <p>
        你还没有{{ $route.meta.index == 0 ? "收藏" : "添加" }}哦，快去{{
          $route.meta.index == 0 ? "收藏" : "添加"
        }}吧
      </p>
    </div>
    <div class="default-item nodata" v-if="status == 'nodata'">
      <img src="@/assets/image/default/nodata.png" alt="" />
      <p>你还没有数据哦，快去看看吧</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Default",
  props: ["status"],
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  destroyed() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.default-page {
  .default-item {
    text-align: center;
    img {
      width: 112px;
      height: 156px;
    }

    p {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 22px;
      margin: 27px auto 23px;
    }
    ul {
      li {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #888888;
        line-height: 26px;
      }
    }
    &.neterror {
      img {
        @include wh(133px, 123px);
      }
      p {
        margin-top: 47px;
      }
    }
    &.nonet {
      img {
        @include wh(146px, 133px);
      }
      p {
        margin-top: 37px;
      }
    }
    &.nocollect {
      img {
        @include wh(145px, 131px);
      }
      p {
        margin-top: 43px;
      }
    }
    &.nodata {
      img {
        @include wh(134px, 138px);
      }
      p {
        margin-top: 39px;
      }
    }
  }
}
</style>
