<template>
  <!-- 个人中心 - 下载记录 -->
  <div class="collect">
    <div class="title">
      <p>下载记录</p>
    </div>
    <div class="shouquan">
      <ul class="type">
        <li :class="type == 1 ? 'active' : ''" @click="changeType(1)">图片</li>
        <li :class="type == 2 ? 'active' : ''" @click="changeType(2)">样图</li>
        <li :class="type == 3 ? 'active' : ''" @click="changeType(3)">视频</li>
      </ul>
      <div class="shouquan-btn" @click="showShouquan = true" v-if="!is_authorized">
        管理授权信息
      </div>
    </div>
    <div class="search">
      <div class="time">
        <div class="text">下载时间：</div>
        <div class="block">
          <el-date-picker v-model="condition.start_time" align="center" type="date" placeholder="下载开始时间"
            :picker-options="pickerOptionStart" @change="getData(1)" value-format="yyyy-MM-dd">
          </el-date-picker>
        </div>
        <div class="line"></div>
        <div class="block">
          <el-date-picker v-model="condition.end_time" align="center" type="date" placeholder="下载结束时间"
            @change="getData(1)" :picker-options="pickerOptionEnd" value-format="yyyy-MM-dd">
          </el-date-picker>
        </div>
      </div>
      <div class="search-input">
        <input class="el-input__inner" @keyup.enter="getData(1)" v-model="condition.keyword"
          :placeholder="inputPlaceholder(type)" />
        <img @click.stop="getData(1)" src="@/assets/image/common/search-999.png" alt="" />
      </div>
    </div>
    <div class="tip">
      共下载<span>{{ total }}</span><i v-if="type <= 2">条图片</i><i v-if="type == 3">条视频</i>
    </div>
    <!-- 图片 -->
    <el-table v-if="type == 1" :data="tableData" border :row-class-name="tableRowClassName"
      :cell-style="{ background: '#fff' }" v-loading="loading" element-loading-background="rgba(255, 255, 255, .5)"
      element-loading-text="加载中，请稍后..." :style="loading ? 'width: 100%;min-height: 400px' : 'width: 100%;'">
      <el-table-column prop="" width="345" label="图片信息">
        <template slot-scope="scope">
          <div class="fl img-box-con">
            <img :src="scope.row.small_preview" alt="" />
            <div v-if="scope.row.grounding != 2" style="
                position: absolute;
                left: 0;
                top: 0;
                line-height: 70px;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                text-align: center;
                color: #fff;
              ">
              图片已下架
            </div>
          </div>
          <div class="fr" style="width: 200px; float: left">
            <div>{{ scope.row.name }}</div>
            <div>{{ scope.row.editor_id }}</div>
            <div>
              {{ scope.row.original_format }}
              <template v-if="scope.row.width">
                {{ scope.row.width + ' x ' + scope.row.height + 'px'}}
              </template>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" width="160" label="下载信息">
        <template slot-scope="scope">
          <div>{{ scope.row.created_at }}</div>
        </template>
      </el-table-column>
      <el-table-column label="授权范围">
        <template slot-scope="scope">
          <div>{{scope.row.license_detail}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="authorize_name" label="授权主体">
        <template slot-scope="scope">
          <div>
            {{ scope.row.authorize_name ? scope.row.authorize_name : "无" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="324">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleDownImg(scope.row, 'original')">重新下载</el-button>
          <el-button size="mini" @click="handleDown(scope.row, 'image')">下载授权书</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 样图 -->
    <el-table v-if="type == 2" :data="tableData" border :cell-style="{ background: '#fff' }" v-loading="loading"
      element-loading-background="rgba(255, 255, 255, .5)" element-loading-text="加载中，请稍后..."
      :style="loading ? 'width: 100%;min-height: 400px' : 'width: 100%;'">
      <el-table-column prop="editor_id" label="图片信息"> </el-table-column>
      <el-table-column label="缩略图">
        <template slot-scope="scope">
          <div class="img"><img :src="scope.row.small_preview" alt="" /></div>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="下载信息" width="160">
        <template slot-scope="scope">
          <div>{{ scope.row.created_at }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="244">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleDownImg(scope.row, 'demo')">重新下载</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 视频 -->
    <el-table v-if="type == 3" :data="tableData" border :cell-style="{ background: '#fff' }" v-loading="loading"
      element-loading-background="rgba(255, 255, 255, .5)" element-loading-text="加载中，请稍后..."
      :style="loading ? 'width: 100%;min-height: 400px' : 'width: 100%;'">
      <el-table-column prop="" width="345" label="视频信息">
        <template slot-scope="scope">
          <div class="fl img-box-con">
            <img :src="scope.row.preview" alt="" />
            <div v-if="scope.row.grounding != 2" style="
                position: absolute;
                left: 0;
                top: 0;
                line-height: 70px;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                text-align: center;
                color: #fff;
              ">
              视频已下架
            </div>
          </div>
          <div class="fr" style="width: 200px; float: left">
            <div>{{ scope.row.name }}</div>
            <div>{{ scope.row.editor_id }}</div>
            <div>
              {{ scope.row.original_format }}
              {{ scope.row.original_byte | fmtSize }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" width="160" label="下载信息">
        <template slot-scope="scope">
          <div>{{ scope.row.created_at }}</div>
        </template>
      </el-table-column>
      <el-table-column label="授权范围">
        <template slot-scope="scope">
          <div>{{scope.row.license_detail}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="authorize_name" label="授权主体">
        <template slot-scope="scope">
          <div>
            {{ scope.row.authorize_name ? scope.row.authorize_name : "无" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="324">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleDownVideo(scope.row)">重新下载</el-button>
          <el-button size="mini" @click="handleDown(scope.row)">下载授权书</el-button>
        </template>
      </el-table-column>
    </el-table>

    <default-page class="nodata" v-if="tableData && tableData.length == 0 && !loading" status="nodata"></default-page>
    <el-pagination background :page-size="condition.page_size" hideOnSinglePage layout="prev, pager, next"
      @current-change="getData" :total="total">
    </el-pagination>
    <!-- 管理授权信息 -->
    <div class="banquan-dialog" v-if="showShouquan">
      <div class="modal edit-img">
        <div class="title">授权信息管理<i class="iconfont icon-close" @click="showShouquan = false"></i></div>
        <div class="form">
          <div class="row">
            <input type="text" v-model="shouquanName" placeholder="请输入被授权方">
          </div>
          <div class="row" style="margin-bottom: 0">
            <div class="submit" @click="editShouquan">确认修改</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  GlobalApi,
  ImgApi,
  VideoApi,
} from "@/config/axios/api";
import DefaultPage from "@/components/default";
import { mapState } from "vuex";

export default {
  name: "Record",
  data () {
    return {
      type: 1, //  1图片   2样图    3视频
      condition: {
        start_time: "", //筛选条件
        end_time: "",
        keyword: "",
        page_size: 30, //30
        page: 1,
      },
      total: 0,
      loading: false,
      tableData: [],
      dataList: [],
      // 开始时间
      pickerOptionStart: {
        disabledDate: (time) => {
          if (this.condition.end_time) {
            return time.getTime() > new Date(this.condition.end_time).getTime();
          }
          return false;
        },
      },
      // 结束时间
      pickerOptionEnd: {
        disabledDate: (time) => {
          if (this.condition.start_time) {
            return (
              time.getTime() <
              new Date(this.condition.start_time + " 00:00:00").getTime() - 1
            );
          }
          return false;
        },
      },
      changeClick: false,
      showShouquan: false,   //是否展示授权弹窗
      shouquanName: '',  //授权名称
      is_authorized: true,  //是否授权过
    };
  },
  components: { DefaultPage },
  created () {
    this.changeType(1);
    this.getShouquan();
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  mounted () {
  },
  destroyed () {
  },
  methods: {
    getShouquan () {
      GlobalApi.getAuthorizeStatus().then(res => {
        if (res.status == 1) {
          this.is_authorized = res.data.is_authorized;
        }
      })
    },
    inputPlaceholder (type) {
      switch (type) {
        case 1:
          return "搜索图片ID";
        case 2:
          return "搜索样图ID";
        case 3:
          return "搜索视频ID";
      }
    },
    changeType (type) {
      if (this.changeClick) {
        return false;
      }
      this.changeClick = true;
      this.tableData = [];
      this.type = type;

      this.condition.start_time = "";
      this.condition.end_time = "";
      this.condition.keyword = "";

      this.tableData = [];
      this.getData(1);
    },
    getData (page) {
      this.loading = true;
      this.condition.page = page;
      let params = {};
      for (let i in this.condition) {
        params[i] = this.condition[i];
      }
      if (params.start_time) {
        params.start_time += " 00:00:00";
      } else {
        delete params.start_time;
      }
      if (params.end_time) {
        params.end_time += " 23:59:59";
      } else {
        delete params.end_time;
      }
      params.cate = this.type;
      if (this.condition.keyword) {
        params.id = this.condition.keyword;
      }
      GlobalApi.getRecordList(params).then((res) => {
        this.loading = false;
        this.changeClick = false;
        if (res.status == 1) {
          this.total = res.data.total;
          this.tableData = res.data.data;
        }
      });
    },
    //图片下载授权书
    handleDown (row) {
      if (this.is_authorized) {
        GlobalApi.downloadRecordPrincipal({ id: row.id }).then(res => {
          if (res.status == 1) {
            this.staticFunc.downloadFile(res.data.oss_url);
            this.$message.success('下载成功');
          }
        })
      } else {
        this.showShouquan = true;
      }
    },
    //下载图片
    handleDownImg (row, type) {
      ImgApi.downloadDemo({ id: row.editor_id, type: type }).then((res) => {
        if (res.status == 1) {
          if (res.data.download_url) {
            this.staticFunc.downloadImg(res.data.download_url, row.editor_id, () => { });
            this.$message.success({ message: res.message ? res.message : "图片下载成功" });
          }
        }
      });
    },
    //下载视频
    handleDownVideo (row) {
      if (this.type == 3 && row.video && row.video.grounding == 1) {
        return;
      }
      if (!this.staticFunc.isLogin()) {
        this.$store.commit("watchShowLogin", true);
        return;
      }
      VideoApi.videoCopyrightDownload({
        id: row.editor_id,
      }).then((res) => {
        if (res.status == 1) {
          if (res.data.download_url) {
            this.staticFunc.downloadImg(res.data.download_url, row.editor_id, () => { });
            this.$message.success({ message: res.msg || '成功' });
          }
        }
      });
    },
    tableRowClassName ({ row }) {
      if (row.images && row.images.grounding == 1) {
        return "noclick";
      }
      return "";
    },
    editShouquan () {
      if (!this.shouquanName.trim()) {
        this.$message.error('请输入被授权方');
        return;
      }
      GlobalApi.addAuthorize({ name: this.shouquanName }).then(res => {
        if (res.status == 1) {
          this.$message.success('添加成功');
          this.is_authorized = true;
          this.showShouquan = false;
        }
      })
    }
  },
};
</script>
<style scoped lang="scss">
.nodata {
  padding: 60px;
}

.img-box-con {
  width: 100px;
  height: 100px;
  float: left;
  margin-right: 10px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  cursor: pointer;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.collect {
  padding: 28px 66px 40px 41px;

  &>.title {
    @include flex(center, space-between);
    height: 42px;
    margin-bottom: 27px;

    p {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #222222;
      line-height: 28px;
    }
  }

  .shouquan {
    @include flex(center, space-between);
    margin-bottom: 35px;
    height: 42px;

    .type {
      @include flex("", "");

      li {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1f1f1f;
        text-align: center;
        line-height: 25px;
        margin-right: 50px;
        cursor: pointer;

        &.active {
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: $activeColor;
          position: relative;

          &::after {
            content: "";
            display: block;
            width: 100%;
            height: 3px;
            background: $activeColor;
            border-radius: 2px;
            position: absolute;
            bottom: -6px;
            left: 1px;
          }
        }
      }
    }

    .shouquan-btn {
      width: 110px;
      height: 42px;
      @include flex(center, center);
      border-radius: 4px;
      border: 1px solid #222222;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1f1f1f;
      cursor: pointer;
    }

    .caozuo {
      height: 42px;
      padding-top: 6px;

      p {
        float: left;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        line-height: 30px;

        span {
          float: left;
          margin: 7px 10px 0 0;
          width: 16px;
          height: 16px;
          @include flex(center, center);
          background: #a8a8a8;
          border-radius: 2px;
          color: #fff;
          font-size: 16px;
          cursor: pointer;
        }
      }

      div {
        float: left;
        padding: 0 14px;
        height: 30px;
        margin-left: 30px;
        background: #f5f8fd;
        border-radius: 4px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #7e87af;
        line-height: 30px;
        cursor: pointer;

        &:last-of-type {
          margin-left: 12px;
        }
      }
    }
  }

  .search {
    margin-bottom: 19px;
    @include flex(center, space-between);

    .text {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444950;
      line-height: 42px;
      float: left;
      margin-right: 3px;
    }

    .time {
      flex-shrink: 0;
      width: 60%;

      .block {
        float: left;
        width: 38%;

        .el-date-editor {
          background: #f7f8fb;
          height: 42px;
          width: 100%;
        }
      }

      .line {
        float: left;
        width: 14px;
        height: 1px;
        background: #7e8792;
        margin: 20px 12px;
      }
    }

    .info {
      flex-shrink: 0;
      width: 320px;

      .el-select {
        height: 42px;
        width: 230px;
        background: #f7f8fb;
      }
    }

    .search-input {
      width: 27.6%;
      height: 42px;
      position: relative;

      img {
        position: absolute;
        top: 13px;
        right: 14px;
        @include wh(16px, 16px);
        cursor: pointer;
        z-index: 1;
      }
    }

    ::v-deep .el-input__inner {
      background: #f7f8fb;
      border: none;
      font-size: 14px;
    }
  }

  .tip {
    text-align: right;
    margin-bottom: 11px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 17px;

    span {
      color: #666;
      font-weight: bold;
    }
  }

  .el-table {
    ::v-deep .el-table__header-wrapper .el-table__cell {
      .cell {
        // padding-left: 25px;
      }

      height: 84px;
      background-color: #f6f7f9;
      font-size: 14px;
      font-family: PingFangSC-Regular,
      PingFang SC;
      font-weight: 400;
      color: #333333;
      border: none;
    }

    ::v-deep .el-table__body-wrapper .el-table__cell {
      height: 66px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      border-color: #f2f2f2;

      .cell {
        // padding-left: 25px;
      }

      .el-button {
        margin-bottom: 8px;

        &.none {
          background: #dbdbdb;
          color: #838383;
          cursor: not-allowed;
        }
      }

      .el-button--default {
        height: 34px;
        padding: 9px 21px;
        background: #f5f8fd;
        border-radius: 4px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7e87af;
        border: none;
      }

      .el-button--danger {
        border: none;
        padding: 9px 21px;
        height: 34px;
        background: #fff1f1;
        border-radius: 4px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff3131;
      }

      .el-button--success {
        border: none;
        padding: 9px 41px;
        height: 34px;
        background: #52c41a;
        border-radius: 4px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }

      .time {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #222222;
        line-height: 20px;
        margin-bottom: 2px;
        padding-left: 25px;

        span {
          display: inline-block;
          width: 20px;
          height: 20px;
          background: #ffffff;
          box-shadow: 0px 0px 4px 0px rgba(196, 196, 196, 0.5);
          border-radius: 2px;
          text-align: center;
          vertical-align: middle;
        }
      }

      .img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        padding: 12px 10px;
        @include flex(center, center);

        img {
          height: 48px;
          object-fit: contain;
        }

        div {
          display: none;
          position: absolute;
          top: 12px;
          bottom: 12px;
          left: 10px;
          right: 10px;
          background: rgba(102, 102, 102, 0.6);
          line-height: 48px;
          color: #fff;
        }

        text-align: center;
      }
    }

    ::v-deep .el-table__row.noclick {
      .el-table__cell {
        color: #bebebe;

        .el-button {
          background: #dbdbdb;
          color: #838383;
          cursor: not-allowed;
        }

        .img {
          div {
            display: block;
          }
        }
      }
    }
  }
}

::v-deep .el-table__empty-block {
  display: none;
}

.banquan-dialog {
  background: rgba(0, 0, 0, .5);

  .modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 514px;
    height: max-content;
    background: #fff;
    border-radius: 8px;
    font-family: PingFangSC-Light;
    z-index: 1011;

    .title {
      width: 100%;
      height: 54px;
      border-bottom: 1px solid rgba(224, 226, 234, 0.66);
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1D2020;
      text-align: center;
      line-height: 54px;
      position: relative;

      i {
        font-size: 14px;
        color: #05061A;
        float: right;
        cursor: pointer;
        position: absolute;
        right: 23px;
      }
    }

    .form {
      padding: 25px 48px 30px;
      width: 100%;

      .row {
        width: 100%;
        margin-bottom: 18px;
        @include flex(center, space-between);

        input {
          width: 100%;
          height: 46px;
          border-radius: 4px;
          border: 1px solid #DEDEDD;
          padding-left: 23px;

          &:hover,
          &:focus {
            border: 1px solid $activeColor;
          }
        }

        img {
          margin-left: 12px;
          width: 114px;
          height: 46px;
          border-radius: 4px;
          border: 1px solid #EBECF1;
          cursor: pointer;
          flex-shrink: 0;
        }

        .send {
          flex-shrink: 0;
          cursor: pointer;
          margin-left: 12px;
          width: 114px;
          height: 46px;
          background: rgba(52, 171, 255, 0.04);
          border-radius: 4px;
          border: 1px solid #EBECF1;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2C86FF;
          line-height: 44px;
          text-align: center;
        }

        .submit {
          width: 100%;
          height: 46px;
          cursor: pointer;
          background: #2C86FF;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 44px;
          text-align: center;
        }
      }

      .tip {
        margin-top: 24px;
        text-align: center;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1D2020;
        line-height: 20px;

        a {
          color: $activeColor;
        }
      }
    }
  }
}
</style>
